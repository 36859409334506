"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.NotificationType = void 0;
const tslib_1 = require("tslib");
const TypeGraphQL = tslib_1.__importStar(require("type-graphql"));
var NotificationType;
(function (NotificationType) {
    NotificationType["NEW_POST"] = "NEW_POST";
    NotificationType["NEW_ACTIVITY"] = "NEW_ACTIVITY";
    NotificationType["NEW_MEMBER"] = "NEW_MEMBER";
    NotificationType["NEW_MENTION"] = "NEW_MENTION";
})(NotificationType = exports.NotificationType || (exports.NotificationType = {}));
TypeGraphQL.registerEnumType(NotificationType, {
    name: "NotificationType",
    description: undefined,
});

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PostMentionScalarFieldEnum = void 0;
const tslib_1 = require("tslib");
const TypeGraphQL = tslib_1.__importStar(require("type-graphql"));
var PostMentionScalarFieldEnum;
(function (PostMentionScalarFieldEnum) {
    PostMentionScalarFieldEnum["id"] = "id";
    PostMentionScalarFieldEnum["name"] = "name";
    PostMentionScalarFieldEnum["postId"] = "postId";
    PostMentionScalarFieldEnum["acterId"] = "acterId";
    PostMentionScalarFieldEnum["createdByUserId"] = "createdByUserId";
})(PostMentionScalarFieldEnum = exports.PostMentionScalarFieldEnum || (exports.PostMentionScalarFieldEnum = {}));
TypeGraphQL.registerEnumType(PostMentionScalarFieldEnum, {
    name: "PostMentionScalarFieldEnum",
    description: undefined,
});

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ActerNotificationEmailFrequency = void 0;
const tslib_1 = require("tslib");
const TypeGraphQL = tslib_1.__importStar(require("type-graphql"));
var ActerNotificationEmailFrequency;
(function (ActerNotificationEmailFrequency) {
    ActerNotificationEmailFrequency["NEVER"] = "NEVER";
    ActerNotificationEmailFrequency["DAILY"] = "DAILY";
    ActerNotificationEmailFrequency["INSTANT"] = "INSTANT";
})(ActerNotificationEmailFrequency = exports.ActerNotificationEmailFrequency || (exports.ActerNotificationEmailFrequency = {}));
TypeGraphQL.registerEnumType(ActerNotificationEmailFrequency, {
    name: "ActerNotificationEmailFrequency",
    description: undefined,
});

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ActerConnectionRole = void 0;
const tslib_1 = require("tslib");
const TypeGraphQL = tslib_1.__importStar(require("type-graphql"));
var ActerConnectionRole;
(function (ActerConnectionRole) {
    ActerConnectionRole["PENDING"] = "PENDING";
    ActerConnectionRole["MEMBER"] = "MEMBER";
    ActerConnectionRole["ADMIN"] = "ADMIN";
    ActerConnectionRole["REJECTED"] = "REJECTED";
    ActerConnectionRole["REMOVED"] = "REMOVED";
})(ActerConnectionRole = exports.ActerConnectionRole || (exports.ActerConnectionRole = {}));
TypeGraphQL.registerEnumType(ActerConnectionRole, {
    name: "ActerConnectionRole",
    description: undefined,
});

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ActivityTypeCount = void 0;
const tslib_1 = require("tslib");
const TypeGraphQL = tslib_1.__importStar(require("type-graphql"));
let ActivityTypeCount = class ActivityTypeCount {
};
tslib_1.__decorate([
    TypeGraphQL.Field(_type => TypeGraphQL.Int, {
        nullable: false
    }),
    tslib_1.__metadata("design:type", Number)
], ActivityTypeCount.prototype, "Activity", void 0);
ActivityTypeCount = tslib_1.__decorate([
    TypeGraphQL.ObjectType("ActivityTypeCount", {
        isAbstract: true
    })
], ActivityTypeCount);
exports.ActivityTypeCount = ActivityTypeCount;

import React, { FC } from 'react'

import { SvgIcon, SvgIconProps } from '@material-ui/core'

export const ActerIcon: FC<SvgIconProps> = (props) => (
  <SvgIcon {...props}>
    <svg
      width="25"
      height="25"
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18.8911 6.37641C18.8911 9.75022 16.0367 12.5028 12.4934 12.5028C8.95013 12.5028 6.0957 9.75022 6.0957 6.37641C6.0957 3.0026 8.95013 0.25 12.4934 0.25C16.0367 0.25 18.8911 3.0026 18.8911 6.37641Z"
        stroke="#D5D5D5"
        strokeWidth="0.5"
      />
      <path
        d="M18.8911 18.6186C18.8911 21.9924 16.0367 24.745 12.4934 24.745C8.95013 24.745 6.0957 21.9924 6.0957 18.6186C6.0957 15.2448 8.95013 12.4922 12.4934 12.4922C16.0367 12.4922 18.8911 15.2448 18.8911 18.6186Z"
        stroke="#D5D5D5"
        strokeWidth="0.5"
      />
      <path
        d="M24.7505 15.0483C24.7505 18.4221 21.896 21.1747 18.3528 21.1747C14.8095 21.1747 11.9551 18.4221 11.9551 15.0483C11.9551 11.6745 14.8095 8.92188 18.3528 8.92188C21.896 8.92188 24.7505 11.6745 24.7505 15.0483Z"
        stroke="#D5D5D5"
        strokeWidth="0.5"
      />
      <path
        d="M13.0454 14.9887C13.0454 18.3625 10.191 21.1151 6.6477 21.1151C3.10443 21.1151 0.25 18.3625 0.25 14.9887C0.25 11.6149 3.10443 8.8623 6.6477 8.8623C10.191 8.8623 13.0454 11.6149 13.0454 14.9887Z"
        stroke="#D5D5D5"
        strokeWidth="0.5"
      />
      <path
        d="M24.7505 9.43696C24.7505 12.8108 21.896 15.5634 18.3528 15.5634C14.8095 15.5634 11.9551 12.8108 11.9551 9.43696C11.9551 6.06315 14.8095 3.31055 18.3528 3.31055C21.896 3.31055 24.7505 6.06315 24.7505 9.43696Z"
        stroke="#D5D5D5"
        strokeWidth="0.5"
      />
      <path
        d="M13.0454 9.37641C13.0454 12.7502 10.191 15.5028 6.6477 15.5028C3.10443 15.5028 0.25 12.7502 0.25 9.37641C0.25 6.0026 3.10443 3.25 6.6477 3.25C10.191 3.25 13.0454 6.0026 13.0454 9.37641Z"
        stroke="#D5D5D5"
        strokeWidth="0.5"
      />
    </svg>
  </SvgIcon>
)

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.InviteScalarFieldEnum = void 0;
const tslib_1 = require("tslib");
const TypeGraphQL = tslib_1.__importStar(require("type-graphql"));
var InviteScalarFieldEnum;
(function (InviteScalarFieldEnum) {
    InviteScalarFieldEnum["id"] = "id";
    InviteScalarFieldEnum["email"] = "email";
    InviteScalarFieldEnum["message"] = "message";
    InviteScalarFieldEnum["error"] = "error";
    InviteScalarFieldEnum["createdAt"] = "createdAt";
    InviteScalarFieldEnum["updatedAt"] = "updatedAt";
    InviteScalarFieldEnum["sentAt"] = "sentAt";
    InviteScalarFieldEnum["acceptedAt"] = "acceptedAt";
    InviteScalarFieldEnum["onActerId"] = "onActerId";
    InviteScalarFieldEnum["createdByUserId"] = "createdByUserId";
    InviteScalarFieldEnum["expiredAt"] = "expiredAt";
})(InviteScalarFieldEnum = exports.InviteScalarFieldEnum || (exports.InviteScalarFieldEnum = {}));
TypeGraphQL.registerEnumType(InviteScalarFieldEnum, {
    name: "InviteScalarFieldEnum",
    description: undefined,
});

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Interest = void 0;
const tslib_1 = require("tslib");
const TypeGraphQL = tslib_1.__importStar(require("type-graphql"));
const InterestCount_1 = require("../resolvers/outputs/InterestCount");
let Interest = class Interest {
};
tslib_1.__decorate([
    TypeGraphQL.Field(_type => String, {
        nullable: false
    }),
    tslib_1.__metadata("design:type", String)
], Interest.prototype, "id", void 0);
tslib_1.__decorate([
    TypeGraphQL.Field(_type => String, {
        nullable: false
    }),
    tslib_1.__metadata("design:type", String)
], Interest.prototype, "name", void 0);
tslib_1.__decorate([
    TypeGraphQL.Field(_type => String, {
        nullable: false
    }),
    tslib_1.__metadata("design:type", String)
], Interest.prototype, "interestTypeId", void 0);
tslib_1.__decorate([
    TypeGraphQL.Field(_type => String, {
        nullable: true
    }),
    tslib_1.__metadata("design:type", String)
], Interest.prototype, "description", void 0);
tslib_1.__decorate([
    TypeGraphQL.Field(_type => String, {
        nullable: true
    }),
    tslib_1.__metadata("design:type", String)
], Interest.prototype, "sdgNumber", void 0);
tslib_1.__decorate([
    TypeGraphQL.Field(_type => InterestCount_1.InterestCount, {
        nullable: true
    }),
    tslib_1.__metadata("design:type", InterestCount_1.InterestCount)
], Interest.prototype, "_count", void 0);
Interest = tslib_1.__decorate([
    TypeGraphQL.ObjectType("Interest", {
        isAbstract: true
    })
], Interest);
exports.Interest = Interest;

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.InterestTypeScalarFieldEnum = void 0;
const tslib_1 = require("tslib");
const TypeGraphQL = tslib_1.__importStar(require("type-graphql"));
var InterestTypeScalarFieldEnum;
(function (InterestTypeScalarFieldEnum) {
    InterestTypeScalarFieldEnum["id"] = "id";
    InterestTypeScalarFieldEnum["name"] = "name";
    InterestTypeScalarFieldEnum["parentInterestTypeId"] = "parentInterestTypeId";
    InterestTypeScalarFieldEnum["sortOrder"] = "sortOrder";
})(InterestTypeScalarFieldEnum = exports.InterestTypeScalarFieldEnum || (exports.InterestTypeScalarFieldEnum = {}));
TypeGraphQL.registerEnumType(InterestTypeScalarFieldEnum, {
    name: "InterestTypeScalarFieldEnum",
    description: undefined,
});

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ActerType = void 0;
const tslib_1 = require("tslib");
const TypeGraphQL = tslib_1.__importStar(require("type-graphql"));
const ActerTypeCount_1 = require("../resolvers/outputs/ActerTypeCount");
let ActerType = class ActerType {
};
tslib_1.__decorate([
    TypeGraphQL.Field(_type => String, {
        nullable: false
    }),
    tslib_1.__metadata("design:type", String)
], ActerType.prototype, "id", void 0);
tslib_1.__decorate([
    TypeGraphQL.Field(_type => String, {
        nullable: false
    }),
    tslib_1.__metadata("design:type", String)
], ActerType.prototype, "name", void 0);
tslib_1.__decorate([
    TypeGraphQL.Field(_type => ActerTypeCount_1.ActerTypeCount, {
        nullable: true
    }),
    tslib_1.__metadata("design:type", ActerTypeCount_1.ActerTypeCount)
], ActerType.prototype, "_count", void 0);
ActerType = tslib_1.__decorate([
    TypeGraphQL.ObjectType("ActerType", {
        isAbstract: true
    })
], ActerType);
exports.ActerType = ActerType;

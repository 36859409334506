"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ActerConnectionScalarFieldEnum = void 0;
const tslib_1 = require("tslib");
const TypeGraphQL = tslib_1.__importStar(require("type-graphql"));
var ActerConnectionScalarFieldEnum;
(function (ActerConnectionScalarFieldEnum) {
    ActerConnectionScalarFieldEnum["id"] = "id";
    ActerConnectionScalarFieldEnum["isApproved"] = "isApproved";
    ActerConnectionScalarFieldEnum["createdAt"] = "createdAt";
    ActerConnectionScalarFieldEnum["updatedAt"] = "updatedAt";
    ActerConnectionScalarFieldEnum["createdByUserId"] = "createdByUserId";
    ActerConnectionScalarFieldEnum["followerActerId"] = "followerActerId";
    ActerConnectionScalarFieldEnum["followingActerId"] = "followingActerId";
    ActerConnectionScalarFieldEnum["role"] = "role";
})(ActerConnectionScalarFieldEnum = exports.ActerConnectionScalarFieldEnum || (exports.ActerConnectionScalarFieldEnum = {}));
TypeGraphQL.registerEnumType(ActerConnectionScalarFieldEnum, {
    name: "ActerConnectionScalarFieldEnum",
    description: undefined,
});

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ActerJoinSettings = void 0;
const tslib_1 = require("tslib");
const TypeGraphQL = tslib_1.__importStar(require("type-graphql"));
var ActerJoinSettings;
(function (ActerJoinSettings) {
    ActerJoinSettings["EVERYONE"] = "EVERYONE";
    ActerJoinSettings["RESTRICTED"] = "RESTRICTED";
    ActerJoinSettings["INVITE_ONLY"] = "INVITE_ONLY";
})(ActerJoinSettings = exports.ActerJoinSettings || (exports.ActerJoinSettings = {}));
TypeGraphQL.registerEnumType(ActerJoinSettings, {
    name: "ActerJoinSettings",
    description: undefined,
});

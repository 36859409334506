"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PostReactionScalarFieldEnum = void 0;
const tslib_1 = require("tslib");
const TypeGraphQL = tslib_1.__importStar(require("type-graphql"));
var PostReactionScalarFieldEnum;
(function (PostReactionScalarFieldEnum) {
    PostReactionScalarFieldEnum["id"] = "id";
    PostReactionScalarFieldEnum["emoji"] = "emoji";
    PostReactionScalarFieldEnum["emojiUnicode"] = "emojiUnicode";
    PostReactionScalarFieldEnum["postId"] = "postId";
    PostReactionScalarFieldEnum["acterId"] = "acterId";
    PostReactionScalarFieldEnum["createdByUserId"] = "createdByUserId";
})(PostReactionScalarFieldEnum = exports.PostReactionScalarFieldEnum || (exports.PostReactionScalarFieldEnum = {}));
TypeGraphQL.registerEnumType(PostReactionScalarFieldEnum, {
    name: "PostReactionScalarFieldEnum",
    description: undefined,
});

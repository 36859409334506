"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.NotificationScalarFieldEnum = void 0;
const tslib_1 = require("tslib");
const TypeGraphQL = tslib_1.__importStar(require("type-graphql"));
var NotificationScalarFieldEnum;
(function (NotificationScalarFieldEnum) {
    NotificationScalarFieldEnum["id"] = "id";
    NotificationScalarFieldEnum["type"] = "type";
    NotificationScalarFieldEnum["url"] = "url";
    NotificationScalarFieldEnum["createdAt"] = "createdAt";
    NotificationScalarFieldEnum["updatedAt"] = "updatedAt";
    NotificationScalarFieldEnum["sendTo"] = "sendTo";
    NotificationScalarFieldEnum["sentAt"] = "sentAt";
    NotificationScalarFieldEnum["viewedAt"] = "viewedAt";
    NotificationScalarFieldEnum["toActerId"] = "toActerId";
    NotificationScalarFieldEnum["onActerId"] = "onActerId";
    NotificationScalarFieldEnum["activityId"] = "activityId";
    NotificationScalarFieldEnum["postId"] = "postId";
    NotificationScalarFieldEnum["postMentionId"] = "postMentionId";
})(NotificationScalarFieldEnum = exports.NotificationScalarFieldEnum || (exports.NotificationScalarFieldEnum = {}));
TypeGraphQL.registerEnumType(NotificationScalarFieldEnum, {
    name: "NotificationScalarFieldEnum",
    description: undefined,
});

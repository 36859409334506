"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ActerTypeScalarFieldEnum = void 0;
const tslib_1 = require("tslib");
const TypeGraphQL = tslib_1.__importStar(require("type-graphql"));
var ActerTypeScalarFieldEnum;
(function (ActerTypeScalarFieldEnum) {
    ActerTypeScalarFieldEnum["id"] = "id";
    ActerTypeScalarFieldEnum["name"] = "name";
})(ActerTypeScalarFieldEnum = exports.ActerTypeScalarFieldEnum || (exports.ActerTypeScalarFieldEnum = {}));
TypeGraphQL.registerEnumType(ActerTypeScalarFieldEnum, {
    name: "ActerTypeScalarFieldEnum",
    description: undefined,
});

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ActerNotificationSettings = void 0;
const tslib_1 = require("tslib");
const TypeGraphQL = tslib_1.__importStar(require("type-graphql"));
var ActerNotificationSettings;
(function (ActerNotificationSettings) {
    ActerNotificationSettings["ALL_ACTIVITY"] = "ALL_ACTIVITY";
    ActerNotificationSettings["NONE"] = "NONE";
    ActerNotificationSettings["MENTIONS"] = "MENTIONS";
})(ActerNotificationSettings = exports.ActerNotificationSettings || (exports.ActerNotificationSettings = {}));
TypeGraphQL.registerEnumType(ActerNotificationSettings, {
    name: "ActerNotificationSettings",
    description: undefined,
});

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ActivityTypeScalarFieldEnum = void 0;
const tslib_1 = require("tslib");
const TypeGraphQL = tslib_1.__importStar(require("type-graphql"));
var ActivityTypeScalarFieldEnum;
(function (ActivityTypeScalarFieldEnum) {
    ActivityTypeScalarFieldEnum["id"] = "id";
    ActivityTypeScalarFieldEnum["name"] = "name";
})(ActivityTypeScalarFieldEnum = exports.ActivityTypeScalarFieldEnum || (exports.ActivityTypeScalarFieldEnum = {}));
TypeGraphQL.registerEnumType(ActivityTypeScalarFieldEnum, {
    name: "ActivityTypeScalarFieldEnum",
    description: undefined,
});

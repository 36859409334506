"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Language = void 0;
const tslib_1 = require("tslib");
const TypeGraphQL = tslib_1.__importStar(require("type-graphql"));
var Language;
(function (Language) {
    Language["en_GB"] = "en_GB";
    Language["da_DK"] = "da_DK";
})(Language = exports.Language || (exports.Language = {}));
TypeGraphQL.registerEnumType(Language, {
    name: "Language",
    description: undefined,
});

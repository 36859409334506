"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ActerConnection = void 0;
const tslib_1 = require("tslib");
const TypeGraphQL = tslib_1.__importStar(require("type-graphql"));
const ActerConnectionRole_1 = require("../enums/ActerConnectionRole");
let ActerConnection = class ActerConnection {
};
tslib_1.__decorate([
    TypeGraphQL.Field(_type => String, {
        nullable: false
    }),
    tslib_1.__metadata("design:type", String)
], ActerConnection.prototype, "id", void 0);
tslib_1.__decorate([
    TypeGraphQL.Field(_type => Boolean, {
        nullable: true
    }),
    tslib_1.__metadata("design:type", Boolean)
], ActerConnection.prototype, "isApproved", void 0);
tslib_1.__decorate([
    TypeGraphQL.Field(_type => Date, {
        nullable: false
    }),
    tslib_1.__metadata("design:type", Date)
], ActerConnection.prototype, "createdAt", void 0);
tslib_1.__decorate([
    TypeGraphQL.Field(_type => Date, {
        nullable: false
    }),
    tslib_1.__metadata("design:type", Date)
], ActerConnection.prototype, "updatedAt", void 0);
tslib_1.__decorate([
    TypeGraphQL.Field(_type => String, {
        nullable: false
    }),
    tslib_1.__metadata("design:type", String)
], ActerConnection.prototype, "createdByUserId", void 0);
tslib_1.__decorate([
    TypeGraphQL.Field(_type => String, {
        nullable: false
    }),
    tslib_1.__metadata("design:type", String)
], ActerConnection.prototype, "followerActerId", void 0);
tslib_1.__decorate([
    TypeGraphQL.Field(_type => String, {
        nullable: false
    }),
    tslib_1.__metadata("design:type", String)
], ActerConnection.prototype, "followingActerId", void 0);
tslib_1.__decorate([
    TypeGraphQL.Field(_type => ActerConnectionRole_1.ActerConnectionRole, {
        nullable: false
    }),
    tslib_1.__metadata("design:type", String)
], ActerConnection.prototype, "role", void 0);
ActerConnection = tslib_1.__decorate([
    TypeGraphQL.ObjectType("ActerConnection", {
        isAbstract: true
    })
], ActerConnection);
exports.ActerConnection = ActerConnection;

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ActerInterestScalarFieldEnum = void 0;
const tslib_1 = require("tslib");
const TypeGraphQL = tslib_1.__importStar(require("type-graphql"));
var ActerInterestScalarFieldEnum;
(function (ActerInterestScalarFieldEnum) {
    ActerInterestScalarFieldEnum["id"] = "id";
    ActerInterestScalarFieldEnum["createdAt"] = "createdAt";
    ActerInterestScalarFieldEnum["updatedAt"] = "updatedAt";
    ActerInterestScalarFieldEnum["createdByUserId"] = "createdByUserId";
    ActerInterestScalarFieldEnum["acterId"] = "acterId";
    ActerInterestScalarFieldEnum["interestId"] = "interestId";
})(ActerInterestScalarFieldEnum = exports.ActerInterestScalarFieldEnum || (exports.ActerInterestScalarFieldEnum = {}));
TypeGraphQL.registerEnumType(ActerInterestScalarFieldEnum, {
    name: "ActerInterestScalarFieldEnum",
    description: undefined,
});

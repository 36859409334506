"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ActivityScalarFieldEnum = void 0;
const tslib_1 = require("tslib");
const TypeGraphQL = tslib_1.__importStar(require("type-graphql"));
var ActivityScalarFieldEnum;
(function (ActivityScalarFieldEnum) {
    ActivityScalarFieldEnum["id"] = "id";
    ActivityScalarFieldEnum["startAt"] = "startAt";
    ActivityScalarFieldEnum["endAt"] = "endAt";
    ActivityScalarFieldEnum["isOnline"] = "isOnline";
    ActivityScalarFieldEnum["createdByUserId"] = "createdByUserId";
    ActivityScalarFieldEnum["createdAt"] = "createdAt";
    ActivityScalarFieldEnum["updatedAt"] = "updatedAt";
    ActivityScalarFieldEnum["acterId"] = "acterId";
    ActivityScalarFieldEnum["organiserId"] = "organiserId";
    ActivityScalarFieldEnum["isAllDay"] = "isAllDay";
    ActivityScalarFieldEnum["activityTypeId"] = "activityTypeId";
})(ActivityScalarFieldEnum = exports.ActivityScalarFieldEnum || (exports.ActivityScalarFieldEnum = {}));
TypeGraphQL.registerEnumType(ActivityScalarFieldEnum, {
    name: "ActivityScalarFieldEnum",
    description: undefined,
});

import React, { FC } from 'react'

import { SvgIcon, SvgIconProps, useTheme } from '@material-ui/core'

export const ProfileIcon: FC<SvgIconProps> = (props) => {
  const theme = useTheme()
  return (
    <SvgIcon {...props}>
      <svg
        width="22"
        height="22"
        viewBox="0 0 20 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clip-path="url(#clip0_1588_42017)">
          <path
            d="M17.5181 9C17.5181 13.6085 13.7626 17.35 9.12313 17.35C4.48363 17.35 0.728125 13.6085 0.728125 9C0.728125 4.39147 4.48363 0.65 9.12313 0.65C13.7626 0.65 17.5181 4.39147 17.5181 9Z"
            stroke={props.style?.color || theme.palette.secondary.light}
            // stroke-width="1.3"
            strokeWidth={props.style?.fontWeight === 'bold' ? 2 : 0.75}
          />
          <mask id="path-2-inside-1_1588_42017" fill="white">
            <path
              strokeWidth={props.style?.fontWeight === 'bold' ? 2 : 0.75}
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M2.33594 14.9531V14.625C2.33594 12.3806 6.85561 11.25 9.11969 11.25C11.3838 11.25 15.9034 12.3806 15.9034 14.625V14.9531C14.2461 16.8215 11.8212 18 9.11969 18C6.41819 18 3.99331 16.8216 2.33594 14.9531ZM9.11969 3.75C7.24568 3.75 5.72781 5.26031 5.72781 7.125C5.72781 8.98969 7.24568 10.5 9.11969 10.5C10.9937 10.5 12.5116 8.98969 12.5116 7.125C12.5116 5.26031 10.9937 3.75 9.11969 3.75Z"
            />
          </mask>
          <path
            d="M2.33594 14.9531H1.03594V15.4466L1.36341 15.8158L2.33594 14.9531ZM15.9034 14.9531L16.876 15.8158L17.2034 15.4466V14.9531H15.9034ZM3.63594 14.9531V14.625H1.03594V14.9531H3.63594ZM3.63594 14.625C3.63594 14.5439 3.66644 14.375 3.95683 14.1083C4.25579 13.8338 4.73263 13.5536 5.36014 13.3035C6.61698 12.8025 8.14931 12.55 9.11969 12.55V9.95C7.82599 9.95 5.96644 10.2628 4.39743 10.8883C3.61201 11.2013 2.81743 11.6246 2.19819 12.1933C1.57039 12.7699 1.03594 13.5839 1.03594 14.625H3.63594ZM9.11969 12.55C10.0901 12.55 11.6224 12.8025 12.8792 13.3035C13.5067 13.5536 13.9836 13.8338 14.2825 14.1083C14.5729 14.375 14.6034 14.5439 14.6034 14.625H17.2034C17.2034 13.5839 16.669 12.7699 16.0412 12.1933C15.4219 11.6246 14.6274 11.2013 13.8419 10.8883C12.2729 10.2628 10.4134 9.95 9.11969 9.95V12.55ZM14.6034 14.625V14.9531H17.2034V14.625H14.6034ZM14.9309 14.0904C13.5106 15.6916 11.4351 16.7 9.11969 16.7V19.3C12.2073 19.3 14.9815 17.9515 16.876 15.8158L14.9309 14.0904ZM9.11969 16.7C6.80429 16.7 4.72877 15.6916 3.30847 14.0904L1.36341 15.8158C3.25785 17.9515 6.03209 19.3 9.11969 19.3V16.7ZM7.02781 7.125C7.02781 5.98439 7.95752 5.05 9.11969 5.05V2.45C6.53383 2.45 4.42781 4.53623 4.42781 7.125H7.02781ZM9.11969 9.2C7.95752 9.2 7.02781 8.26561 7.02781 7.125H4.42781C4.42781 9.71376 6.53383 11.8 9.11969 11.8V9.2ZM11.2116 7.125C11.2116 8.26561 10.2818 9.2 9.11969 9.2V11.8C11.7055 11.8 13.8116 9.71376 13.8116 7.125H11.2116ZM9.11969 5.05C10.2818 5.05 11.2116 5.98439 11.2116 7.125H13.8116C13.8116 4.53624 11.7055 2.45 9.11969 2.45V5.05Z"
            fill={props.style?.color || theme.palette.secondary.light}
            mask="url(#path-2-inside-1_1588_42017)"
            // strokeWidth={props.style?.fontWeight === 'bold' ? 2 : 0.75}
          />
        </g>
        <defs>
          <clipPath id="clip0_1588_42017">
            <rect
              width="18.09"
              height="18"
              fill="white"
              transform="translate(0.078125)"
            />
          </clipPath>
        </defs>
      </svg>
    </SvgIcon>
  )
}

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ActerPrivacySettings = void 0;
const tslib_1 = require("tslib");
const TypeGraphQL = tslib_1.__importStar(require("type-graphql"));
var ActerPrivacySettings;
(function (ActerPrivacySettings) {
    ActerPrivacySettings["PUBLIC"] = "PUBLIC";
    ActerPrivacySettings["PRIVATE"] = "PRIVATE";
})(ActerPrivacySettings = exports.ActerPrivacySettings || (exports.ActerPrivacySettings = {}));
TypeGraphQL.registerEnumType(ActerPrivacySettings, {
    name: "ActerPrivacySettings",
    description: undefined,
});

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.LinkScalarFieldEnum = void 0;
const tslib_1 = require("tslib");
const TypeGraphQL = tslib_1.__importStar(require("type-graphql"));
var LinkScalarFieldEnum;
(function (LinkScalarFieldEnum) {
    LinkScalarFieldEnum["id"] = "id";
    LinkScalarFieldEnum["name"] = "name";
    LinkScalarFieldEnum["url"] = "url";
    LinkScalarFieldEnum["createdAt"] = "createdAt";
    LinkScalarFieldEnum["updatedAt"] = "updatedAt";
    LinkScalarFieldEnum["acterId"] = "acterId";
    LinkScalarFieldEnum["createdByUserId"] = "createdByUserId";
})(LinkScalarFieldEnum = exports.LinkScalarFieldEnum || (exports.LinkScalarFieldEnum = {}));
TypeGraphQL.registerEnumType(LinkScalarFieldEnum, {
    name: "LinkScalarFieldEnum",
    description: undefined,
});

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PostMention = void 0;
const tslib_1 = require("tslib");
const TypeGraphQL = tslib_1.__importStar(require("type-graphql"));
const PostMentionCount_1 = require("../resolvers/outputs/PostMentionCount");
let PostMention = class PostMention {
};
tslib_1.__decorate([
    TypeGraphQL.Field(_type => String, {
        nullable: false
    }),
    tslib_1.__metadata("design:type", String)
], PostMention.prototype, "id", void 0);
tslib_1.__decorate([
    TypeGraphQL.Field(_type => String, {
        nullable: false
    }),
    tslib_1.__metadata("design:type", String)
], PostMention.prototype, "name", void 0);
tslib_1.__decorate([
    TypeGraphQL.Field(_type => String, {
        nullable: false
    }),
    tslib_1.__metadata("design:type", String)
], PostMention.prototype, "postId", void 0);
tslib_1.__decorate([
    TypeGraphQL.Field(_type => String, {
        nullable: false
    }),
    tslib_1.__metadata("design:type", String)
], PostMention.prototype, "acterId", void 0);
tslib_1.__decorate([
    TypeGraphQL.Field(_type => String, {
        nullable: false
    }),
    tslib_1.__metadata("design:type", String)
], PostMention.prototype, "createdByUserId", void 0);
tslib_1.__decorate([
    TypeGraphQL.Field(_type => PostMentionCount_1.PostMentionCount, {
        nullable: true
    }),
    tslib_1.__metadata("design:type", PostMentionCount_1.PostMentionCount)
], PostMention.prototype, "_count", void 0);
PostMention = tslib_1.__decorate([
    TypeGraphQL.ObjectType("PostMention", {
        isAbstract: true
    })
], PostMention);
exports.PostMention = PostMention;

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ActerScalarFieldEnum = void 0;
const tslib_1 = require("tslib");
const TypeGraphQL = tslib_1.__importStar(require("type-graphql"));
var ActerScalarFieldEnum;
(function (ActerScalarFieldEnum) {
    ActerScalarFieldEnum["id"] = "id";
    ActerScalarFieldEnum["acterTypeId"] = "acterTypeId";
    ActerScalarFieldEnum["name"] = "name";
    ActerScalarFieldEnum["slug"] = "slug";
    ActerScalarFieldEnum["description"] = "description";
    ActerScalarFieldEnum["location"] = "location";
    ActerScalarFieldEnum["locationLat"] = "locationLat";
    ActerScalarFieldEnum["locationLng"] = "locationLng";
    ActerScalarFieldEnum["url"] = "url";
    ActerScalarFieldEnum["createdAt"] = "createdAt";
    ActerScalarFieldEnum["updatedAt"] = "updatedAt";
    ActerScalarFieldEnum["createdByUserId"] = "createdByUserId";
    ActerScalarFieldEnum["parentActerId"] = "parentActerId";
    ActerScalarFieldEnum["avatarUrl"] = "avatarUrl";
    ActerScalarFieldEnum["bannerUrl"] = "bannerUrl";
    ActerScalarFieldEnum["deletedAt"] = "deletedAt";
    ActerScalarFieldEnum["deletedByUserId"] = "deletedByUserId";
    ActerScalarFieldEnum["acterJoinSetting"] = "acterJoinSetting";
    ActerScalarFieldEnum["acterNotifyEmailFrequency"] = "acterNotifyEmailFrequency";
    ActerScalarFieldEnum["acterNotifySetting"] = "acterNotifySetting";
    ActerScalarFieldEnum["acterPrivacySetting"] = "acterPrivacySetting";
    ActerScalarFieldEnum["acterWhoCanJoinSetting"] = "acterWhoCanJoinSetting";
    ActerScalarFieldEnum["placeId"] = "placeId";
    ActerScalarFieldEnum["isMarkDown"] = "isMarkDown";
})(ActerScalarFieldEnum = exports.ActerScalarFieldEnum || (exports.ActerScalarFieldEnum = {}));
TypeGraphQL.registerEnumType(ActerScalarFieldEnum, {
    name: "ActerScalarFieldEnum",
    description: undefined,
});

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.InterestScalarFieldEnum = void 0;
const tslib_1 = require("tslib");
const TypeGraphQL = tslib_1.__importStar(require("type-graphql"));
var InterestScalarFieldEnum;
(function (InterestScalarFieldEnum) {
    InterestScalarFieldEnum["id"] = "id";
    InterestScalarFieldEnum["name"] = "name";
    InterestScalarFieldEnum["interestTypeId"] = "interestTypeId";
    InterestScalarFieldEnum["description"] = "description";
    InterestScalarFieldEnum["sdgNumber"] = "sdgNumber";
})(InterestScalarFieldEnum = exports.InterestScalarFieldEnum || (exports.InterestScalarFieldEnum = {}));
TypeGraphQL.registerEnumType(InterestScalarFieldEnum, {
    name: "InterestScalarFieldEnum",
    description: undefined,
});

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ActerWhoCanJoinSettings = void 0;
const tslib_1 = require("tslib");
const TypeGraphQL = tslib_1.__importStar(require("type-graphql"));
var ActerWhoCanJoinSettings;
(function (ActerWhoCanJoinSettings) {
    ActerWhoCanJoinSettings["ALL"] = "ALL";
    ActerWhoCanJoinSettings["PEOPLE"] = "PEOPLE";
    ActerWhoCanJoinSettings["ACTERS"] = "ACTERS";
})(ActerWhoCanJoinSettings = exports.ActerWhoCanJoinSettings || (exports.ActerWhoCanJoinSettings = {}));
TypeGraphQL.registerEnumType(ActerWhoCanJoinSettings, {
    name: "ActerWhoCanJoinSettings",
    description: undefined,
});

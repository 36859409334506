"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Acter = void 0;
const tslib_1 = require("tslib");
const TypeGraphQL = tslib_1.__importStar(require("type-graphql"));
const ActerJoinSettings_1 = require("../enums/ActerJoinSettings");
const ActerNotificationEmailFrequency_1 = require("../enums/ActerNotificationEmailFrequency");
const ActerNotificationSettings_1 = require("../enums/ActerNotificationSettings");
const ActerPrivacySettings_1 = require("../enums/ActerPrivacySettings");
const ActerWhoCanJoinSettings_1 = require("../enums/ActerWhoCanJoinSettings");
const ActerCount_1 = require("../resolvers/outputs/ActerCount");
let Acter = class Acter {
};
tslib_1.__decorate([
    TypeGraphQL.Field(_type => String, {
        nullable: false
    }),
    tslib_1.__metadata("design:type", String)
], Acter.prototype, "id", void 0);
tslib_1.__decorate([
    TypeGraphQL.Field(_type => String, {
        nullable: false
    }),
    tslib_1.__metadata("design:type", String)
], Acter.prototype, "acterTypeId", void 0);
tslib_1.__decorate([
    TypeGraphQL.Field(_type => String, {
        nullable: true
    }),
    tslib_1.__metadata("design:type", String)
], Acter.prototype, "name", void 0);
tslib_1.__decorate([
    TypeGraphQL.Field(_type => String, {
        nullable: true
    }),
    tslib_1.__metadata("design:type", String)
], Acter.prototype, "slug", void 0);
tslib_1.__decorate([
    TypeGraphQL.Field(_type => String, {
        nullable: true
    }),
    tslib_1.__metadata("design:type", String)
], Acter.prototype, "description", void 0);
tslib_1.__decorate([
    TypeGraphQL.Field(_type => String, {
        nullable: true
    }),
    tslib_1.__metadata("design:type", String)
], Acter.prototype, "location", void 0);
tslib_1.__decorate([
    TypeGraphQL.Field(_type => TypeGraphQL.Float, {
        nullable: true
    }),
    tslib_1.__metadata("design:type", Number)
], Acter.prototype, "locationLat", void 0);
tslib_1.__decorate([
    TypeGraphQL.Field(_type => TypeGraphQL.Float, {
        nullable: true
    }),
    tslib_1.__metadata("design:type", Number)
], Acter.prototype, "locationLng", void 0);
tslib_1.__decorate([
    TypeGraphQL.Field(_type => String, {
        nullable: true
    }),
    tslib_1.__metadata("design:type", String)
], Acter.prototype, "url", void 0);
tslib_1.__decorate([
    TypeGraphQL.Field(_type => Date, {
        nullable: false
    }),
    tslib_1.__metadata("design:type", Date)
], Acter.prototype, "createdAt", void 0);
tslib_1.__decorate([
    TypeGraphQL.Field(_type => Date, {
        nullable: false
    }),
    tslib_1.__metadata("design:type", Date)
], Acter.prototype, "updatedAt", void 0);
tslib_1.__decorate([
    TypeGraphQL.Field(_type => String, {
        nullable: false
    }),
    tslib_1.__metadata("design:type", String)
], Acter.prototype, "createdByUserId", void 0);
tslib_1.__decorate([
    TypeGraphQL.Field(_type => String, {
        nullable: true
    }),
    tslib_1.__metadata("design:type", String)
], Acter.prototype, "parentActerId", void 0);
tslib_1.__decorate([
    TypeGraphQL.Field(_type => String, {
        nullable: true
    }),
    tslib_1.__metadata("design:type", String)
], Acter.prototype, "avatarUrl", void 0);
tslib_1.__decorate([
    TypeGraphQL.Field(_type => String, {
        nullable: true
    }),
    tslib_1.__metadata("design:type", String)
], Acter.prototype, "bannerUrl", void 0);
tslib_1.__decorate([
    TypeGraphQL.Field(_type => Date, {
        nullable: true
    }),
    tslib_1.__metadata("design:type", Date)
], Acter.prototype, "deletedAt", void 0);
tslib_1.__decorate([
    TypeGraphQL.Field(_type => String, {
        nullable: true
    }),
    tslib_1.__metadata("design:type", String)
], Acter.prototype, "deletedByUserId", void 0);
tslib_1.__decorate([
    TypeGraphQL.Field(_type => ActerJoinSettings_1.ActerJoinSettings, {
        nullable: false
    }),
    tslib_1.__metadata("design:type", String)
], Acter.prototype, "acterJoinSetting", void 0);
tslib_1.__decorate([
    TypeGraphQL.Field(_type => ActerNotificationEmailFrequency_1.ActerNotificationEmailFrequency, {
        nullable: false
    }),
    tslib_1.__metadata("design:type", String)
], Acter.prototype, "acterNotifyEmailFrequency", void 0);
tslib_1.__decorate([
    TypeGraphQL.Field(_type => ActerNotificationSettings_1.ActerNotificationSettings, {
        nullable: false
    }),
    tslib_1.__metadata("design:type", String)
], Acter.prototype, "acterNotifySetting", void 0);
tslib_1.__decorate([
    TypeGraphQL.Field(_type => ActerPrivacySettings_1.ActerPrivacySettings, {
        nullable: false
    }),
    tslib_1.__metadata("design:type", String)
], Acter.prototype, "acterPrivacySetting", void 0);
tslib_1.__decorate([
    TypeGraphQL.Field(_type => ActerWhoCanJoinSettings_1.ActerWhoCanJoinSettings, {
        nullable: false
    }),
    tslib_1.__metadata("design:type", String)
], Acter.prototype, "acterWhoCanJoinSetting", void 0);
tslib_1.__decorate([
    TypeGraphQL.Field(_type => String, {
        nullable: true
    }),
    tslib_1.__metadata("design:type", String)
], Acter.prototype, "placeId", void 0);
tslib_1.__decorate([
    TypeGraphQL.Field(_type => Boolean, {
        nullable: false
    }),
    tslib_1.__metadata("design:type", Boolean)
], Acter.prototype, "isMarkDown", void 0);
tslib_1.__decorate([
    TypeGraphQL.Field(_type => ActerCount_1.ActerCount, {
        nullable: true
    }),
    tslib_1.__metadata("design:type", ActerCount_1.ActerCount)
], Acter.prototype, "_count", void 0);
Acter = tslib_1.__decorate([
    TypeGraphQL.ObjectType("Acter", {
        isAbstract: true
    })
], Acter);
exports.Acter = Acter;
